body > .underlay > header,
body > .underlay > footer {
  display: flex;

  .site-logo {
    margin: 0;
    padding: 0;
    line-height: .5;
    font-size: 24px;
    font-weight: 600;
    white-space: nowrap;
    color: white;

    :link, :hover, :visited {
      color: inherit;
    }

    svg {
      height: 30px;
      vertical-align: middle;
    }

    @media screen and (min-width: $bigscreen-breakpoint) {
      margin-right: 100px;
    }
  }
  .social-links {
    > a {
      margin-left: 14px;
      font-size: 18px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}


.underlay.header {
  body > & {
    background: $header-background;

    > .hero {
      padding-top: 20px;
      color: white;

      > .text {
        position: relative;

        > .title {
          font-size: 44px;
          font-weight: 700;
          margin: 0 0 20px 0;
          line-height: 1.2;
        }
        > .desc {
          font-size: 20px;
          margin: 0 0 20px 0;
        }
        > .cta {  // Call to action.
          margin-top: 40px;

          .button {
            &:not(:first-child) {
              @include cta-button(rgba(white, 0.32), white);
            }
            &:first-child {
              @include cta-button(white, $primary-dark-color);
            }
            &:last-child {
              margin-right: 0;
            }
            &:only-child {
              @include cta-button($primary-dark-color, white);
            }
          }
        }
      }
    }
  }
  .site--hub.layout--software-index > & {
    padding-bottom: 2em;
    background: $hub-software--hero-background;
  }
  .site--hub.layout--blog-index > &,
  .site--hub.layout--project-index > & {
    padding-bottom: 2em;
  }
  .site--hub.layout--spec-index > & {
    padding-bottom: 2em;
    background: $hub-specs--hero-background;
  }
  .site--hub.layout--home > & {
    padding-bottom: 2em;
    background: $main-background;
  }
  .site--project.layout--software-index > &,
  .site--project.layout--spec-index > &,
  .site--project.layout--blog-index > & {
    padding-bottom: 2em;
  }
  .site--project.layout--home > & {
    background: none;
  }

  .site--project.layout--home > & {
    > .hero {
      .text {
        margin-top: 1.5em;
        margin-bottom: 2em;
      }
    }
  }
  
  .site--hub.layout--home > & {
    > .hero {
      text-align: left;

      @media screen and (min-width: $bigscreen-breakpoint) {
        padding-bottom: 50px;
      }
    }
  }
}

body > .underlay.footer {
  background: rgba(black, 0.4);
}

body > .underlay > header {
  padding-top: 26px;
  padding-bottom: 26px;
  z-index: 5;
  // Higher than hero, otherwise Algolia search pop-up might have stuff over it

  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;

  > button.hamburger {
    border: 0;
    background: transparent;
    font-size: inherit;
    z-index: 20;
    color: white;
  }

  > .hamburger-menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    transition: transform .8s cubic-bezier(0.23, 1, 0.32, 1);
    color: white;

    height: 100vh;
    overflow: hidden;
    z-index: 10;
    background: rgba($primary-dark-color, 0.95);

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;

    .site-logo-container {
      margin-left: 2em;
      margin-top: 26px;
      align-self: flex-start;
    }

    > nav,
    > .social-links {
      a {
        &:link, &:visited, &:hover {
          font-size: 1.3em;
          color: white;
        }
      }
    }

    > nav {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      align-self: stretch;

      a.search {
        display: none;
      }
      .search-widget {
        margin-top: 2em;
        margin-bottom: 1em;
      }

      > * {
        margin: .25em;
        margin-left: 2em;
      }
      > .active {
        font-weight: bold;
      }
    }

    .social-links {
      margin-top: 1em;
      margin-bottom: 2em;
    }

    &.expanded {
      transform: translateY(0);
    }
  }

  > .top-menu > .search-widget,
  > .hamburger-menu > nav > .search-widget {
    input[type=search] {
      padding: 6px 10px 6px 10px;
      border-radius: 1em;
      font-size: inherit;
      line-height: inherit;
      border: 0;
      color: white;
    }
  }

  > .top-menu {
    flex: 1;

    > :not(.search-widget) {
      display: inline-block;
      font-weight: 600;
      margin-right: 20px;
      padding: 6px 10px 6px 10px;
      white-space: nowrap;
    }
    > .search-widget {
      width: 0;
      display: none;
      position: relative;

      > * {
        flex: 1;
      }
    }
    &.with-expanded-search {
      > a {
        display: none;
      }
      > .search-widget {
        width: 90%;
        display: inline-flex;
      }
    }
  }

  > .top-menu,
  > .social-links {
    color: white;
    display: none;
    white-space: nowrap;

    .active {
      background-color: rgba(black, 0.1);
    }
    a:link, a:visited, a:hover {
      color: white;
    }
  }

  @media screen and (min-width: $bigscreen-breakpoint) {
    justify-content: unset;

    > button.hamburger {
      display: none;
    }
    > .top-menu, > .social-links {
      display: block;
    }
  }
}

body > .underlay > footer {
  padding-top: 50px;
  padding-bottom: 50px;

  align-items: flex-start;
  flex-flow: column nowrap;

  color: white;

  a:link, a:visited, a:hover {
    color: white;
  }

  .parent-hub-plug {
    .logo {
      display: block;
    }

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    white-space: nowrap;
    line-height: .5;
    opacity: 0.8;
    color: white;

    @media screen and (min-width: $bigscreen-breakpoint) {
      margin-right: 50px;
    }

    .label {
      margin-right: 20px;
      font-weight: 500;
    }
  }

  nav {
    display: flex;
    flex-flow: row wrap;

    > * {
      margin-right: 40px;
    }
  }

  .links {
    font-weight: bold;
    opacity: 0.8;
    margin-bottom: 1em;
  }

  .legal {
    font-size: 14px;

    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;

    margin-top: 1em;
    margin-bottom: 1em;

    .copyright {
      .copyright-head, .copyright-tail {
        white-space: nowrap;
      }
    }

    @media screen and (min-width: $widescreen-breakpoint) {
      flex-flow: row nowrap;

      nav {
        display: block;
      }
      .copyright {
        margin-right: 40px;
      }
    }
  }
}
