.headroom {
    will-change: transform;
    transition: transform .1s linear;
}
.headroom--pinned {
    transform: translateY(0%);
}
.headroom--unpinned {
    transform: translateY(-100%);
}

body.with-headroom {
  &.docs-page {
    .underlay.header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 6;
    }
  }
}
